import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { paymentMethodId: String, clientSecret: String, status: String }

  connect() {
    const status = this.statusValue;
    if (status !== 'requires_action') {
      return;
    }

    const stripePublicKey = document.querySelector('meta[name="stripe-key"]').getAttribute("content");
    const stripeAccountId = document.querySelector('meta[name="stripe-account-id"]').getAttribute("content");
    this.stripe = Stripe(stripePublicKey, {
      stripeAccount: stripeAccountId
    });

    const clientSecret = this.clientSecretValue;
    const paymentMethod = this.paymentMethodIdValue;

    this.stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod,

    }).then((result) => {
      console.log("Result:", result);
      if (result.error) {
        console.error('Error:', result.error.message);
        $("#help-text").text(result.error.message);
        alert('Payment authentication failed. Please try again.');
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        alert('Payment successful!');
        $("#help-text").text("Payment successful!");
        location.reload(); // Refresh or redirect
      } else if (result.paymentIntent && result.paymentIntent.status === 'requires_payment_method') {
        $("#help-text").text("Payment failed. Please provide a different payment method.");
        alert('Payment failed. Please provide a different payment method.');
      }
    });
  }
}
