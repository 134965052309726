import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    var vm = this;

    document.addEventListener('turbo:load', function () {
      if (vm.element.dataset.patient === "true") {
        vm.select2 = $(vm.element).select2({
          width: '100%',
          placeholder: 'Select an option',
          allowClear: true,
          tags: true, // Enable free text input
          createTag: function (params) {
            // Check if the entered text doesn't match existing options
            var existingOptions = $(vm.element).find('option').map(function() {
              return this.text.toLowerCase();
            }).get();

            if (existingOptions.indexOf(params.term.toLowerCase()) === -1) {
              return {
                id: params.term,
                text: params.term,
                isNew: true
              };
            }
            return null;
          },
          templateResult: function (data) {
            var $result = $("<span></span>");
            $result.text(data.text);
            if (data.isNew) {
              $result.append(" <em>(Add a new patient or client)</em>");
            }
            return $result;
          }
        });

        // Handle new patient creation
        vm.select2.on('select2:select', async function (e) {
          var data = e.params.data;


          // If it's a new patient
          if (data.isNew) {
            var isPatient = true;
            var splittedName = data.text.split(' ');
            var first_name = splittedName.shift();
            var last_name = splittedName.join(' ') || '';

            if ($('#flexSwitchCheckDefault')[0]) {
              isPatient = $('#flexSwitchCheckDefault')[0].checked;
            }
            console.log("isPatient", isPatient);
            // return;
            try {
              const response = await fetch('/api/patients', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify({
                  patient: {
                    first_name: first_name,
                    last_name: last_name,
                    is_patient: isPatient
                  }
                })
              });

              if (!response.ok) {
                throw new Error('Failed to create patient');
              }

              const newPatient = await response.json();

              // Replace the temporary option with the new patient
              const $select = $(vm.element);
              $select.find(`option[value="${data.id}"]`).remove();
              const newOption = new Option(newPatient.name, newPatient.id, true, true);
              $select.append(newOption).trigger('change');

              // Dispatch change event
              var el = vm.element;
              var ev = document.createEvent('Event');
              ev.initEvent('change', true, false);
              el.dispatchEvent(ev);
              // location reload, and find or create patient_id in url and set as newPatient.id before doing that..

              // Find or create patient_id and update URL
              var newPatientId = newPatient.id; // Assuming the new patient ID is in data.id
              var url = new URL(window.location.href);
              url.searchParams.set('patient_id', newPatientId);
              window.location.href = url.toString();

            } catch (error) {
              console.error('Error creating patient:', error);
              // Remove the temporary option
              $(vm.element).find(`option[value="${data.id}"]`).remove();

              // Optionally show an error message to the user
              alert('Failed to create patient. Please try again.');
            }
          }
        });
      } else if (vm.element.dataset.companyMailingList === "true") {
        vm.select2 = $(vm.element).select2({
          width: '100%',
          placeholder: 'Select an option',
          allowClear: true,
          tags: true,
          multiple: true,
          tokenSeparators: [' '],
          createTag: function (params) {
            const email = params.term.trim();
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (emailRegex.test(email)) {
              return {
                id: email,
                text: email
              };
            }
          
            return null;
          }
        });
      } else {
        vm.select2 = $(vm.element).select2({
          width: '100%',
          placeholder: 'Select an option',
          allowClear: true
        });
      }

      vm.select2.on('select2:select select2:unselect', function (e) {
        var data = e.params.data;
        var el = vm.element;
        var ev = document.createEvent('Event');
        var elm = e.params.data.element;
        var $elm = $(elm);
        var $t = $(this);
        $t.append($elm);
        $t.trigger('change.select2');
        ev.initEvent('change', true, false);
        el.dispatchEvent(ev);
      });
    });
  }
}
