import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import monthSelect from 'flatpickr/dist/plugins/monthSelect';

export default class extends Controller {
  static targets = ["reportsDateRange", "reportsMonthRange"]

  connect() {
    if (this.hasReportsDateRangeTarget) {
      flatpickr(this.reportsDateRangeTarget, {
        mode: "range",
      });
    }

    if (this.hasReportsMonthRangeTarget) {
      flatpickr(this.reportsMonthRangeTarget, {
        mode: "range",
        plugins: [
          new monthSelect({
            shorthand: true,
            dateFormat: "M Y",
          })
        ]
      });
    }
  }
}
